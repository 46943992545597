<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getSingleTeamMemberQuery",
  
  watch: {
    singleTeamMember() {
      this.$store.commit('graphqlData', { item: 'singleTeamMember', result: this.singleTeamMember });
     },
  },
    
  setup() {
    const store = useStore();
    
    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
      slug: computed(() => store.state.data.athleteSlug)
    });
    
    useClient({
      url: process.env.VUE_APP_GRAPHCMS_ATHLETE_URL,
      cachePolicy: 'network-only'
    });
    
    const getSingleTeamMemberQuery = gql`
     query MyQuery($slug: String!, $lang:Locale!) {
      team(where: {slug: $slug}, locales: [$lang]) {
        instagram
        instagramSlug
        klettern
        name
        routen
        tags
        tip
        training
        zitat
        highlights
      }
    }
    `;
    
    const { data: singleTeamMember } = useQuery({
      query: getSingleTeamMemberQuery,
      variables
    });
    
    return { singleTeamMember };
  },
}
</script>