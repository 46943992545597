<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getSingleTeamMemberImagesQuery",
  
  watch: {
    singleTeamMemberImages() {
      this.$store.commit('graphqlData', { item: 'singleTeamMemberImages', result: this.singleTeamMemberImages });
     },
  },
    
  setup() {
    const store = useStore();
    
    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
      slug: computed(() => store.state.data.athleteSlug)
    });
    
    useClient({
      url: process.env.VUE_APP_GRAPHCMS_ATHLETE_URL,
    });
    
    const getSingleTeamMemberImagesQuery = gql`
     query MyQuery($slug: String!) {
      team(where: {slug: $slug}) {
        imageCredits
        images {
          url(
            transformation: {document: {output: {format: webp}}, image: {resize: {height: 1000}}}
          )
        }
      }
    }
    `;
    
    const { data: singleTeamMemberImages } = useQuery({
      query: getSingleTeamMemberImagesQuery,
      variables
    });
    
    return { singleTeamMemberImages };
  },
}
</script>