<template>
  <div class="font-stolzl text-coolGray-900 overflow-hidden md:overflow-visible">
    <div class="w-screen flex flex-col md:flex-row justify-center mb-10">
      
      <!-- TOP // LEFT -->
      <div v-if="teamMemberImages?.team" id="imageContainer" class="h-55 relative md:h-auto w-screen fixed top-0 z-0 md:static md:w-1/2 md:min-h-full">
        <div class="absolute z-20 inset-x-0 -bottom-4 flex flex-row justify-center items-center px-8 md:hidden">
          <div v-if="teamMemberImages.team.images.length > 1" v-for="(image,index) in teamMemberImages.team.images">
            <div v-if="currentImageIndex == index" class="w-1.5 h-1.5 mx-2 rounded-full ring-1 ring-opacity-80 bg-coolGray-900 ring-coolGray-900"></div>
            <div v-else class="w-1.5 h-1.5 mx-2 rounded-full ring-1 ring-opacity-80 ring-coolGray-900"></div>
          </div>
        </div>
        <div id="slider" class="relative flex flex-row w-screen h-full md:h-auto md:w-full md:flex-col">
        <div v-for="(image,index) in teamMemberImages.team.images" :id="index" class="relative flex-shrink-0 w-full h-full bg-local bg-center bg-no-repeat bg-cover md:min-h-full md:h-screen bg-coolGray-100" v-bind:style="{ backgroundImage: 'url(' + image.url + ')' }"
          @touchstart.passive="dragStart" @touchend.passive="dragEnd" @touchmove="drag"
          @mousedown.passive="dragStart" @mouseup.passive="dragEnd" @mousemove="drag">
          <div v-if="imageCredits && imageCredits[index] && !imageCredits[index].includes('NONE')" class="absolute bottom-2 -right-0 text-xs font-light text-coolGray-600 transform origin-bottom-right rotate-90">Foto: {{imageCredits[index]}}</div>
        </div>
        </div>
      </div>
      <!-- TOP // LEFT -->
      
      <!-- BOTTOM // RIGHT -->
      <div v-if="teamMember?.team" class="md:sticky top-0 w-full md:w-1/2 md:h-screen pt-8 md:pt-16 flex flex-row justify-start items-center px-4 md:px-8">
        <div class="md:pl-6 md:pr-16 w-full">
          <div class="font-bold text-3xl text-coolGray-900">{{teamMember.team.name}}</div>
          <a v-if="teamMember.team.instagram && teamMember.team.instagramSlug" class="flex flex-row gap-1 cursor-pointer" :href="teamMember.team.instagram" target="_blank">
            <div  class="text-sm font-light">{{teamMember.team.instagramSlug}}</div>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </a>

          <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
            <div v-if="currentTab == 0 && teamMember.team.klettern" class="mt-5 w-full">
              <div>{{teamMember.team.zitat || 'Warum kletterst du?'}}</div>
              <div class="font-light text-base mt-2 text-coolGray-900 whitespace-pre-line">{{teamMember.team.klettern}}</div>
            </div>
            
            <div v-else-if="currentTab == 1" class="mt-5 w-full">
              <div>Training</div>
              <div class="font-light text-base mt-2 text-coolGray-900 whitespace-pre-line">{{teamMember.team.training}}</div>
            </div>
            
            <div v-else-if="currentTab == 2" class="mt-5 w-full">
              <div>Tips</div>
              <div class="font-light text-base mt-2 text-coolGray-900 whitespace-pre-line">{{teamMember.team.tip}}</div>
            </div>
            
            <div v-else-if="currentTab == 3" class="mt-5 w-full">
              <div>Routesetting</div>
              <div class="font-light text-base mt-2 text-coolGray-900 whitespace-pre-line">{{teamMember.team.routen}}</div>
            </div>
            
            <div v-else-if="currentTab == 4" class="mt-5 w-full">
              <div>Highlights</div>
              <div class="font-light text-base mt-2 text-coolGray-900 whitespace-pre-line">{{teamMember.team.highlights}}</div>
            </div>
          </transition>
          
          <div class="flex flex-col md:flex-row justify-start items-start md:items-center mt-8 w-full">
            
            <div v-if="teamMember.team.klettern" class="flex flex-row gap-1 justify-center items-center group cursor-pointer mr-1 md:mr-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-out h-5 md:h-4 w-5 md:w-4 text-coolGray-400 group-hover:text-coolGray-900" 
                :class="{'text-coolGray-900' : currentTab == 0}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
              </svg>
              <div class="transition duration-300 ease-out font-light text-base md:text-sm mt-0.5 text-coolGray-400 group-hover:text-coolGray-900" 
                :class="{'text-coolGray-900' : currentTab == 0}" 
                @click="currentTab = 0">{{teamMember.team.name.split(" ")[0]}}</div>
            </div>
            
            <div v-if="teamMember.team.training" class="flex flex-row gap-1 justify-center items-center group cursor-pointer mr-1 md:mr-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-out h-5 md:h-4 w-5 md:w-4 text-coolGray-400 group-hover:text-coolGray-900"  
                :class="{'text-coolGray-900' : currentTab == 1}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
              </svg>
              <div class="transition duration-300 ease-out font-light text-base md:text-sm mt-0.5 text-coolGray-400 group-hover:text-coolGray-900" 
                :class="{'text-coolGray-900' : currentTab == 1}" 
                @click="currentTab = 1">Training</div>
            </div>
            
            <div v-if="teamMember.team.tip" class="flex flex-row gap-1 justify-center items-center group cursor-pointer mr-1 md:mr-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-out h-5 md:h-4 w-5 md:w-4 text-coolGray-400 group-hover:text-coolGray-900"
                :class="{'text-coolGray-900' : currentTab == 2}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14" />
              </svg>
              <div class="transition duration-300 ease-out font-light text-base md:text-sm mt-0.5 text-coolGray-400 group-hover:text-coolGray-900" 
                :class="{'text-coolGray-900' : currentTab == 2}" 
                @click="currentTab = 2">Tips</div>
            </div>
            
            <div v-if="teamMember.team.routen" class="flex flex-row gap-1 justify-center items-center group cursor-pointer mr-1 md:mr-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-out h-5 md:h-4 w-5 md:w-4 text-coolGray-400 group-hover:text-coolGray-900"
                :class="{'text-coolGray-900' : currentTab == 3}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
              </svg>
              <div class="transition duration-300 ease-out font-light text-base md:text-sm mt-0.5 text-coolGray-400 group-hover:text-coolGray-900" 
                :class="{'text-coolGray-900' : currentTab == 3}" 
                @click="currentTab = 3">Routesetting</div>
            </div>
            
            <div v-if="teamMember.team.highlights" class="flex flex-row gap-1 justify-center items-center group cursor-pointer mr-1 md:mr-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-out h-5 md:h-4 w-5 md:w-4 text-coolGray-400 group-hover:text-coolGray-900"
                :class="{'text-coolGray-900' : currentTab == 4}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
              <div class="transition duration-300 ease-out font-light text-base md:text-sm mt-0.5 text-coolGray-400 group-hover:text-coolGray-900" 
                :class="{'text-coolGray-900' : currentTab == 4}" 
                @click="currentTab = 4">Highlights</div>
            </div>

          </div>
          
        </div>
      </div>
      <!-- BOTTOM // RIGHT -->
        
    </div>
    <!-- COMPONENTS -->
    <Footer />
    <getSingleTeamMemberQuery />
    <getSingleTeamMemberImagesQuery />
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';

import scrollToTop from '../mixins/scrollToTop.vue';

import Footer from '../components/footer.vue';
import getSingleTeamMemberQuery from '../graphql/graphcms/getSingleTeamMemberQuery.vue';
import getSingleTeamMemberImagesQuery from '../graphql/graphcms/getSingleTeamMemberImagesQuery.vue';

export default {
  name: 'TeamMember',
  components: { Footer, getSingleTeamMemberQuery, getSingleTeamMemberImagesQuery },
  mixins: [ scrollToTop ],
  props: ['slug'],
  
  data(){
    return{
      currentTab: 0,
      currentImageIndex: 0,
      activeDragDirection: '',
      movePosition:'100',
      screenWidth: document.documentElement.clientWidth,
      dragItem: '',
      currentX: '',
      currentY: '',
      initialX: '',
      initialY: '',
      endX:''
    }
  },
  
  computed: {
    //graphql
    teamMember(){
      return this.$store.state.data.singleTeamMember;
    },
    teamMemberImages(){
      return this.$store.state.data.singleTeamMemberImages;
    },
    //data
    imageCredits(){
      if(this.teamMemberImages?.team?.imageCredits){
        var credits = this.teamMemberImages.team.imageCredits.split(',');
        return credits;
      }
    }
  },
  
  watch:{
    teamMember(){
      document.title = this.teamMember?.team?.name;
      document.querySelector('meta[name="description"]').setAttribute("content", this.teamMember?.team?.zitat);  
    }
  },
  
  mounted(){
    this.$store.commit('setAthleteSlug', this.slug);
  },
  
  methods:{
    
    //----------------------------------------------//
    //----------------TOUCH ACTIONS-----------------//
    //----------------------------------------------//
    
    dragStart(e){
      this.dragItem = e.srcElement.closest("#slider");
      
      if (e.type === "touchstart") {
        this.initialX = e.touches[0].clientX;
        this.initialY = e.touches[0].clientY;
      }
      
    },
    
    drag(e){
      
      if (e.type === "touchmove") {
          this.currentX = e.touches[0].clientX - this.initialX;
          this.currentY = e.touches[0].clientY - this.initialY;
          this.endX = e.touches[0].clientX;
        } 
     
      let distance = Math.abs(this.currentX) - Math.abs(this.currentY) 
     
      if(Math.abs(distance) > 10 && this.activeDragDirection == ''){
        if(Math.abs(this.currentX) > Math.abs(this.currentY) ){
          this.activeDragDirection = 'horizontal';
        } else {
          this.activeDragDirection = 'vertical';
        }
      }
      
      if(this.activeDragDirection == 'horizontal' ){
        e.preventDefault();
        
        var moving = this.currentX + this.screenWidth * (this.currentImageIndex * -1)
        anime.set(this.dragItem, {
            translateX: moving
          });

      }
    },
    
    dragEnd(e){
     var that = this;
      if(this.activeDragDirection == 'horizontal'){
      if(this.initialX > this.endX){
        //swipe left
         if(this.currentImageIndex == this.teamMemberImages.team.images.length -1){
          //bounce back
          anime({
            targets: this.dragItem,
            easing: 'easeInOutCubic',
            duration: 250,
            translateX: '-' + this.movePosition
          });
        } else {
          //move
          this.currentImageIndex++;
          this.movePosition = this.currentImageIndex * this.screenWidth
          anime({
              targets: this.dragItem,
              easing: 'easeOutSine',
              duration: 100,
              translateX: '-' + this.movePosition
            });
        }
      } else {
        //swipe right
        if(this.currentImageIndex == 0){
          //bounce back
          anime({
            targets: this.dragItem,
            easing: 'easeInOutCubic',
            duration: 250,
            translateX: 0
          });
        } else {
          //move
          this.currentImageIndex--;
          this.movePosition = this.currentImageIndex * this.screenWidth
          anime({
              targets: this.dragItem,
              easing: 'easeOutSine',
              duration: 100,
              translateX: '-' + this.movePosition
            });
        }
        
      }
      }
      this.activeDragDirection = ''
      this.currentX = 0;

    },
    
    //----------------------------------------------//
    //------------------ANIMATIONS------------------//
    //----------------------------------------------//
    
    fadeInGrowEnter(el, done){
      var last = this.lastElementHeight + 'px';
      var current = el.offsetHeight + 'px';
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 350,
        opacity: [0,1],
        height: [last,current],
        complete: done
      });
    },
    
    fadeInGrowLeave(el, done){
      this.lastElementHeight = el.offsetHeight;
      anime({
        targets: el,
        easing: 'linear',
        duration: 0,
        opacity: 0,
        complete: done
      });
    },
  }

};
</script>